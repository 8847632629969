// Ajax検索した時にhistory APIにURLをpush

import cleanupQuery from '../utils/cleanup_query';

// Ajax検索を履歴に追加
export default function (url, query = null) {
  try {
    let newUrl = url;

    // パラメータが指定されていない場合は、検索条件からパラメータ生成
    let newQuery = query === null ? $('#search_form').serialize() : query;
    if (newQuery !== '') {
      newQuery = cleanupQuery(newQuery, ['utf8', 'search_cond_template[id]', 'search_cond_template%5Bid%5D', 'download_token']);
      const connector = url.indexOf('?') === -1 ? '?' : '&';
      newUrl += connector + newQuery;
    }

    // stateのパラメータにturbolinks用の値を渡す。そうしないとturbolinksでうまく機能しない
    window.history.pushState({ turbolinks: true, newUrl }, null, newUrl);
  } catch (e) {
    // 履歴に残す処理関連でエラーになった場合はスルー。通常の操作に影響しないように。
  }
}
