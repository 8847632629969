// 数字のフォーマット（カンマ区切り）

import { removeComma } from './string_number_util';

// 数字をカンマ区切りにする
export default class NumberFormat {
  constructor(maxFractionDigits = 0, minimumFractionDigits = 0) {
    this.formatter = new Intl.NumberFormat('ja-JP', { maximumFractionDigits: maxFractionDigits, minimumFractionDigits });
  }

  format(number) {
    if (number === '') return null;

    const formattedNumber = this.formatter.format(removeComma(number));
    return formattedNumber === 'NaN' ? null : formattedNumber;
  }
}
