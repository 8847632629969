// 全角数字を半角数字に変換する
// 対象にするには、class="hankaku_input"を付ける

import { convertToHankakuNum } from '../utils/string_number_util';
import { isEnter } from '../utils/keyevent_util';

// 全角を半角に変換する
const convertInputValue = (e) => {
  if (e.type === 'focusout' || (e.type === 'keyup' && isEnter(e))) {
    e.target.value = convertToHankakuNum(e.target.value);
  }
};

// 自動フォーマットのイベントを登録
const setupConvertEvent = (element) => {
  element.addEventListener('keyup', convertInputValue);
  element.addEventListener('focusout', convertInputValue);
};

// 自動フォーマットのイベントを削除
const removeConvertEvent = (element) => {
  element.removeEventListener('keyup', convertInputValue);
  element.removeEventListener('focusout', convertInputValue);
};

export default function () {
  // テキストボックスにfocusが当たった時に、自動フォーマットする処理を発火するイベントを設定する
  document.addEventListener('focusin', (e) => {
    if (e.target.classList !== undefined && e.target.classList.contains('hankaku_input')) setupConvertEvent(e.target);
  });

  document.addEventListener('focusout', (e) => {
    // テキストボックスからfocusが外れた時に、自動フォーマットする処理を発火するイベントを削除する
    if (e.target.classList !== undefined && e.target.classList.contains('hankaku_input')) removeConvertEvent(e.target);
  });
}
