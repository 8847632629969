// 一覧のテーブルフッター・ヘッダー固定

let localStorageKey = null;

// 固定・解除の切り替え
const switchTableSticky = (element) => {
  const stickyTableWrapper = document.getElementById('sticky_table_wrapper');
  const switchTableStickyOnElement = document.getElementById('switch_table_sticky_on');
  const switchTableStickyOffElement = document.getElementById('switch_table_sticky_off');

  if (element.id === 'switch_table_sticky_on') {
    // 固定する
    stickyTableWrapper.classList.add('sticky_table_wrapper');

    switchTableStickyOnElement.style.display = 'none';
    switchTableStickyOffElement.style.display = '';
    localStorage.setItem(localStorageKey, true);

    // 表の位置をもとに、固定する高さを決める
    // 120pxは、表のヘッダー・フッター・ページネーション部分のざっくり合計
    const rect = stickyTableWrapper.getBoundingClientRect();
    stickyTableWrapper.style.minHeight = '50px';
    stickyTableWrapper.style.maxHeight = `calc(100vh - ${rect.top}px - 120px)`;
  } else if (element.id === 'switch_table_sticky_off') {
    // 固定を解除する
    stickyTableWrapper.classList.remove('sticky_table_wrapper');
    switchTableStickyOnElement.style.display = '';
    switchTableStickyOffElement.style.display = 'none';
    localStorage.setItem(localStorageKey, false);

    // ONにした際に高さを計算してインラインで指定しているのでそれをクリアする
    stickyTableWrapper.style.maxHeight = '';
  }
};

// 固定・解除の切り替えボタンのイベント
const setupSwitchTableStickyButton = (e) => {
  const element = e.target;
  if (element.id !== 'switch_table_sticky_on' && element.id !== 'switch_table_sticky_off') return;
  switchTableSticky(element);
};

// 初期設定
const setInitialSetting = () => {
  const tableStickySetting = localStorage.getItem(localStorageKey);
  if (tableStickySetting == null) {
    localStorage.setItem(localStorageKey, false); // デフォルトOFF
  } else if (tableStickySetting === 'true') {
    // デフォルトがOFFになっているのでONにする
    switchTableSticky(document.getElementById('switch_table_sticky_on'));
  }
};

export default function () {
  if (window.isIE()) {
    const element = document.getElementById('sticky_table_wrapper');
    if (element !== null) element.classList.remove('sticky_table_wrapper');
    return; // 非対応
  }

  const controllerName = document.body.getAttribute('data-controller_name');
  const actionName = document.body.getAttribute('data-action_name');
  localStorageKey = `table_sticky_${serverVariables.userId}_${controllerName}_${actionName}`;

  const stickyTableWrapper = document.getElementById('sticky_table_wrapper');
  if (stickyTableWrapper == null) return; // 検索結果がない画面は何もしない

  setInitialSetting(); // 初めての場合は初期設定を登録
  stickyTableWrapper.addEventListener('click', setupSwitchTableStickyButton);
}
