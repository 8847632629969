// toast通知

export default function (message) {
  let element = document.getElementById('toast_notice');

  if (element !== null) {
    if (window.toastTimer !== null) window.clearTimeout(window.toastTimer);

    if (window.isIE()) {
      element.parentNode.removeChild(element);
    } else {
      element.remove();
    }
  }

  document.body.insertAdjacentHTML('beforeend', `<div id="toast_notice" class="alert fadein" role="status"><div>${message}</div><button type="button" class="close" data-dismiss="alert" aria-label="Close" data-test-id="toast_notice_close_button"><span aria-hidden="true" style="vertical-align: text-top;">&times;</span></button></div>`);
  element = document.getElementById('toast_notice');

  return window.setTimeout(() => { window.toastTimer = window.fadeInOut(element, 5000); }, 100);
}
