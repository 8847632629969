import controllerLoader from './parts_setup/controller_loader';
import setupLoading from './parts_setup/loading';
import setupTooltip from './parts_setup/tooltip';
import setupPopover from './parts_setup/popover';
import setupChoices from './parts_setup/choices';
import setupDatepicker from './parts_setup/datepicker';
import setupAutoNumberFormat from './parts_setup/auto_number_format';
import setupConvertToHankakuNumber from './parts_setup/convert_to_hankaku_number';
import initNumberFormat from './parts_setup/init_number_format';
import setupClipboard from './parts_setup/clipboard';
import setupDialog from './parts_setup/dialog';
import setupAlgoliaHelpSearch from './parts_setup/algolia_help_search';
import setupReturnUrl from './parts_setup/return_url';
import scrollToTop from './utils/scroll_to_top';
import showToastNotificationOnLoad from './parts_setup/show_toast_notification';
import convertJsonCharacter from './parts_setup/convert_json_character';
import fadeIn from './utils/fadein';
import fadeOut from './utils/fadeout';
import fadeInOut from './utils/fadein_out';
import fadeOutIn from './utils/fadeout_in';
import renderSearchResult from './parts_setup/render_search_result';
import setSortValue from './parts_setup/set_sort_value';
import removeErrorMessageArea from './parts_setup/remove_error_message_area';
import replaceScript from './parts_setup/replace_script';
import setupHightChartGlobalSetting from './parts_setup/highcharts/global_setting';
import polyfillMatches from './utils/polyfill/matches'; // eslint-disable-line no-unused-vars
import polyfillReplaceWith from './utils/polyfill/replace_with'; // eslint-disable-line no-unused-vars
import triggerEvent from './utils/trigger_event';
import triggerCustomEvent from './utils/trigger_custom_event';
import {
  isIE, isEdge, isSafari, isFirefox, isiOS, isAndroid,
} from './utils/browser';
import showToastNotification from './utils/toast_notification';

// 一覧画面用
import setupAutoSearch from './parts_setup/auto_search';
import setupSearchConditionTemplate from './parts_setup/search_condition_template';
import setupClickRow from './parts_setup/click_row';
import setupListForm from './parts_setup/list_form';
import setupListVisibleFields from './parts_setup/list_visible_fields';
import setupTableSticky from './parts_setup/table_sticky';
import setupToggleAllChecksInTable from './parts_setup/toggle_all_checks_in_table';
import setupCsvDownloadInSearch from './parts_setup/csv_download_in_search';
import pushAjaxSearchHistory from './parts_setup/push_ajax_search_history';

// xxx.js.erbから呼び出せるように、グローバルにする
window.scrollToTop = scrollToTop;
window.fadeIn = fadeIn;
window.fadeOut = fadeOut;
window.fadeInOut = fadeInOut;
window.fadeOutIn = fadeOutIn;
window.renderSearchResult = renderSearchResult;
window.setSortValue = setSortValue;
window.removeErrorMessageArea = removeErrorMessageArea;
window.replaceScript = replaceScript;
window.triggerEvent = triggerEvent;
window.triggerCustomEvent = triggerCustomEvent;
window.isIE = isIE;
window.isEdge = isEdge;
window.isSafari = isSafari;
window.isFirefox = isFirefox;
window.isiOS = isiOS;
window.isAndroid = isAndroid;
window.showToastNotification = showToastNotification;
window.setupChoices = setupChoices;
window.convertJsonCharacter = convertJsonCharacter;

// 共通定義
window.showLoadingMode = true;
window.adminUrl = 'https://admin.the-board.jp/';
window.loadingDiv = '<div id="loading_on_main" class="progress progress-small progress-striped active" style="width: 80%; max-width: 400px;margin: 100px auto;"><div class="progress-bar" style="width: 100%;"></div></div>';

// 画面遷移時にリセットするもの
const initWhenTurbolinksLoad = () => {
  window.choicesElements = new Map(); // turbolinksでの遷移の場合、windowオブジェクト内のものは維持されるため、リセットする
  window.fixedChoicesList = {}; // choicesのリストで、Ajaxで一度取得済で、件数が少なくて再取得する必要がないもの
};

// index画面用共通処理（documentにイベント設定するもの）
const initIndexOnDocument = () => {
  setupAutoSearch(); // 自動検索実行処理
  setupClickRow(); // 行クリック時の画面遷移
  setupToggleAllChecksInTable(); // tableのチェック全ON/OFF切り替え
  setupCsvDownloadInSearch(); // CSVダウンロード
  setupSearchConditionTemplate(); // 検索条件テンプレート
  setupListVisibleFields(); // 検索結果表示設定

  // ページネーションのリンクの仕組み上、onclickに指定するため、グローバルで使えるようにする
  window.pushAjaxSearchHistory = pushAjaxSearchHistory;
};

// スクリーンリーダー読み上げ用に画面名のaria-labelにページ番号をセットする
const setPageNumberOnPageTitle = () => {
  const pageNameElement = document.getElementById('page_name');
  if (pageNameElement === null) return;

  const searchParams = new URLSearchParams(window.location.search);
  const pageNum = searchParams.get('page');

  if (pageNum === null) {
    pageNameElement.setAttribute('aria-label', pageNameElement.innerHTML);
  } else {
    pageNameElement.setAttribute('aria-label', `${pageNameElement.innerHTML} ${pageNum}ページ目`);
  }
};

// スクリーンリーダー読み上げ用にTurbolinksで遷移した後にページタイトルに強制的にフォーカスを当てるために使用する
const focusPageTitle = () => {
  const pageNameElement = document.getElementById('page_name');
  if (pageNameElement !== null) pageNameElement.focus();
};

// index画面用共通処理（検索実行時などリロードした後にも呼び出すもの。イベントの設定がdocumentではなくリロードされる対象）
window.initIndex = () => {
  setupListForm(); // 一覧のform
  setupTableSticky(); // テーブルヘッダー・フッター固定の切り替え
  setPageNumberOnPageTitle();
  focusPageTitle();
};

// 画面表示時の共通処理
window.initPage = (controllerName, actionName) => {
  setupChoices();
  initNumberFormat(); // 表示されている数字をカンマ区切りにする
  setupDatepicker();
  setupAlgoliaHelpSearch();
  setupReturnUrl();

  // コントローラごとのモジュールをimport
  if (actionName === 'new' || actionName === 'create' || actionName === 'edit' || actionName === 'update') {
    controllerLoader(controllerName, 'form'); // formは各アクション共通
  } else {
    controllerLoader(controllerName, actionName);
  }
};

// DOMContentLoaded時に呼び出される処理（turbolinksで遷移の際は呼び出されない）
// 多くの画面で使われるような処理はここで定義する
document.addEventListener('DOMContentLoaded', () => {
  setupLoading();
  setupPopover();
  setupAutoNumberFormat(); // 数字をカンマ区切りに自動フォーマットするイベントの登録
  setupConvertToHankakuNumber(); // 全角数字→半角数字変換
  setupClipboard();
  setupDialog();
  setupHightChartGlobalSetting(); // Highchartのグローバル設定（一度だけ呼び出されればOKなのでここで呼び出す）
});

// 画面表示時・turbolinksで遷移時に呼び出される処理
document.addEventListener('turbolinks:load', () => {
  // メイン部分を表示（turbolinks:before-visitで非表示にしているのを戻す）
  const pageContentElement = document.getElementById('page-content');
  const turbolinksVisitControl = document.querySelector('meta[name="turbolinks-visit-control"]');
  if (pageContentElement != null && turbolinksVisitControl !== null && turbolinksVisitControl !== 'reload') {
    // Turbolinks OFFのmetaタグがある場合はopacityは戻さない。戻すと、一瞬元の画面が表示されてから画面遷移されるためおかしい
    pageContentElement.style.opacity = 1;
  }

  const { body } = document;
  if (body == null) return;

  const controllerName = body.getAttribute('data-controller_name');
  const actionName = body.getAttribute('data-action_name');
  window.indexPageSearched = false; // 検索が実行されたか（Ajaxによる検索結果の差し替えと区別したい時に使う）

  initWhenTurbolinksLoad();

  window.initPage(controllerName, actionName);

  // index画面共通処理
  if (actionName === 'index') {
    initIndexOnDocument();
    window.initIndex();
  }

  showToastNotificationOnLoad(); // toast通知を表示する
  setupTooltip();
  focusPageTitle();
});

// Turbolinksによる遷移が始まる直前
document.addEventListener('turbolinks:before-visit', () => {
  // メイン部分を非表示（画面遷移がわかりにくいので）
  const pageContentElement = document.getElementById('page-content');
  if (pageContentElement != null) pageContentElement.style.opacity = 0;
});
