// 確認ダイアログ、アラートダイアログのセットアップ
// /common/modal_dialog/dialogにコンテンツをセットして、A11yDialogでダイアログの表示/非表示
//
// ■要件
// - 確認ダイアログ（OK・キャンセル）
// window.dialogConfirm.show()を呼び出すか、class="js_dialog_confirm"とdata属性"data-dialog-confirm"で内容をセットする
// 例）<%= f.submit "削除", class: "js_dialog_confirm", data: { dialog_confirm: "削除してもよろしいですか。" } %>
//
// - アラートダイアログ（OKのみ）
// window.dialogAlert.show()を呼び出すか、class="js_dialog_alert"を付与
// 例）<a href="xxx" class="js_dialog_alert" data-message="メッセージ">リンク</a>

// 他のJS処理からも呼び出せるようにするため
window.dialogConfirm = {};
window.dialogAlert = {};

// ダイアログ内の各親要素から子要素をクリア
const clearElementsInDialog = (parentElements) => {
  parentElements.forEach((element) => {
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
  });
};

const insertElementsInDialog = ({ title = '', message = '', buttons }) => {
  const dialogTitle = document.getElementById('dialog_title');
  const dialogBody = document.getElementById('dialog_body');
  const dialogFooter = document.getElementById('dialog_footer');
  const elements = [dialogTitle, dialogBody, dialogFooter];

  // NVDAのブラウズモードで操作すると、ボタンなど重複生成されてしまうため
  clearElementsInDialog(elements);

  dialogTitle.innerHTML = title;
  dialogBody.innerHTML = message;
  buttons.forEach((button) => {
    dialogFooter.appendChild(button);
  });
  // ダイアログを閉じる際のクリア処理用に要素の配列を返す
  return elements;
};

const createButtonElement = ({
  name = '', className, testId, isAutofocus, canHideDialog,
}) => {
  const button = document.createElement('button');
  button.type = 'button';
  button.innerHTML = name;
  if (className !== undefined) button.className = className;
  if (testId !== undefined) button.setAttribute('data-test-id', testId);
  if (isAutofocus === true) button.autofocus = true;
  if (canHideDialog === true) button.setAttribute('data-a11y-dialog-hide', true);
  return button;
};

const isShowingOtherModals = () => {
  if (document.getElementById('common_modal_area').innerHTML !== '' || document.getElementById('common_sub_modal_area').innerHTML !== '') return true;
  return false;
};

// ************* dialog confirm ******************
// confirmを表示
window.dialogConfirm.show = (triggerElement, message, callbackFunction = null) => {
  if (triggerElement.dataset.skipDialogConfirm === 'true') return;

  const dialogElement = document.getElementById('common_dialog_form');
  dialogElement.setAttribute('role', 'alertdialog');

  const cancelBtton = createButtonElement({
    name: 'キャンセル',
    className: 'btn btn-light btn-sm',
    canHideDialog: true,
  });

  const okButton = createButtonElement({
    name: 'OK',
    className: 'btn btn-primary btn-sm',
    testId: 'dialog_confirm_ok_button',
    isAutofocus: true,
  });

  const elementsInDialog = insertElementsInDialog({ title: '確認', message, buttons: [cancelBtton, okButton] });

  const dialog = new window.A11yDialog(dialogElement);

  okButton.addEventListener('click', (event) => {
    if (callbackFunction === null) { // callback未指定時はsubmitする
      // callback未指定時は、ボタン自体がsubmitという想定で処理する
      // data-skip-dialog-confirmとonlickを空にして、再度同じ処理が発生せず、そのままsubmitさせる
      triggerElement.setAttribute('data-skip-dialog-confirm', true); // 再度ダイアログが表示されないようにする
      if (event && triggerElement.getAttribute('type') === 'submit') {
        triggerElement.setAttribute('onclick', null);
        dialog.hide();
        triggerElement.click(); // ダイアログをスキップする設定にしたので、再度クリックを発火させてsubmitする
      }
    } else {
      callbackFunction();
      dialog.hide();
    }
  });

  // ライブラリ側でrole="alertdialog"の場合は、Escキーで閉じられないようにしているための自前で実装
  const escapeKeyEvent = (event) => {
    if (event.code === 'Escape') dialog.hide();
  };
  dialogElement.addEventListener('keydown', escapeKeyEvent);

  dialog
    .on('show', () => { window.disableBodyScroll(dialogElement, { allowTouchMove: () => true }); })
    .on('hide', () => {
      // 他のモーダルに重ねて表示している場合は、スクロールをロックしたままにする
      if (isShowingOtherModals() === false) window.enableBodyScroll(dialogElement);
      clearElementsInDialog(elementsInDialog);
      dialogElement.removeEventListener('keydown', escapeKeyEvent);

      // 表示元のツールチップを非表示にする
      const currentlyFocusedTooltip = document.activeElement._tippy; // eslint-disable-line no-underscore-dangle
      if (currentlyFocusedTooltip !== undefined) currentlyFocusedTooltip.hide();
    })
    .show();
};

// confirmを表示（clickイベントから呼び出し）
const showDialogConfirm = (e) => {
  if (e.target.classList === undefined || !e.target.classList.contains('js_dialog_confirm')) return;

  const { target } = e;

  if (target.dataset.dialogConfirm !== '') {
    if (target.dataset.skipDialogConfirm !== 'true') e.preventDefault();

    window.dialogConfirm.show(target, target.dataset.dialogConfirm);
  }
};

// dialog_confirmをセットアップ
const setupDialogConfirm = () => {
  document.addEventListener('click', showDialogConfirm);
};
// ************* end of dialog confirm ******************

// ************* dialog alert ******************
// alertを表示
window.dialogAlert.show = (message, title = '確認') => {
  const dialogElement = document.getElementById('common_dialog_form');
  dialogElement.setAttribute('role', 'dialog');

  const okButton = createButtonElement({
    name: 'OK',
    className: 'btn btn-primary btn-sm',
    testId: 'dialog_confirm_ok_button',
    isAutofocus: true,
    canHideDialog: true,
  });

  const elementsInDialog = insertElementsInDialog({ title, message, buttons: [okButton] });

  const dialog = new window.A11yDialog(dialogElement);
  dialog
    .on('show', () => { window.disableBodyScroll(dialogElement, { allowTouchMove: () => true }); })
    .on('hide', () => {
      // 他のモーダルに重ねて表示している場合は、スクロールをロックしたままにする
      if (isShowingOtherModals() === false) window.enableBodyScroll(dialogElement);
      clearElementsInDialog(elementsInDialog);
      // 表示元のツールチップを非表示にする
      const currentlyFocusedTooltip = document.activeElement._tippy; // eslint-disable-line no-underscore-dangle
      if (currentlyFocusedTooltip !== undefined) currentlyFocusedTooltip.hide();
    })
    .show();
};

// alertを表示（clickイベントから呼び出し）
const showDialogAlert = (e) => {
  if (e.target.classList === undefined || !e.target.classList.contains('js_dialog_alert')) return;

  const { target } = e;
  window.dialogAlert.show(target.dataset.message, target.dataset.title);

  e.preventDefault();
  e.stopPropagation();
};

// dialog_alertをセットアップ
const setupDialogAlert = () => {
  document.addEventListener('click', showDialogAlert);
};
// ************* end of dialog alert ******************

export default function () {
  setupDialogConfirm();
  setupDialogAlert();
}
