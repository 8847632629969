// 検索条件テンプレート

import cleanupQuery from '../utils/cleanup_query';

// 保存ダイアログ呼び出し
const showSaveDialog = (e) => {
  let query = $('#search_cond').closest('form').serialize(); // JQueryのserialize()の代替がないためとりあえずそのまま
  query = cleanupQuery(query, ['utf8', 'download_token'], 'q[d]='); // パラメータが全て空の場合のダミーを指定（検索条件未指定という状態を保存・呼び出しできるように）

  const element = e.target;
  Rails.ajax({
    type: 'GET',
    url: `/search_condition_templates/new?search_conditions=${encodeURIComponent(query)}&list_kbn=${e.target.dataset.list_kbn}`,
    beforeSend: () => {
      if (element !== null) element.classList.add('disabled');
      window.triggerEvent(document, 'ajax:send'); // なぜかイベントが発火しないため
      return true;
    },
    complete: () => {
      if (element !== null) element.classList.remove('disabled');
      window.triggerEvent(document, 'ajax:complete'); // なぜかイベントが発火しないため
    },
  });
};

// 削除
const deleteSearchConditionTemplate = (e) => {
  const searchCondTemplateId = document.getElementById('search_cond_template_id').value;
  if (searchCondTemplateId === '') {
    window.dialogAlert.show('削除対象の検索条件テンプレートを選択してください。', '未選択');
    return;
  }

  const element = e.target;
  window.dialogConfirm.show(element, '検索条件テンプレートを削除してもよろしいですか。', () => {
    Rails.ajax({
      type: 'DELETE',
      url: `/search_condition_templates/${searchCondTemplateId}`,
      beforeSend: () => {
        if (element !== null) element.classList.add('disabled');
        window.triggerEvent(document, 'ajax:send'); // なぜかイベントが発火しないため
        return true;
      },
      complete: () => {
        if (element !== null) element.classList.remove('disabled');
        window.triggerEvent(document, 'ajax:complete'); // なぜかイベントが発火しないため
      },
    });
  });
};

// デフォルト切り替え
const swichDefault = (e) => {
  const searchCondTemplateId = document.getElementById('search_cond_template_id').value;
  if (searchCondTemplateId === '') {
    window.dialogAlert.show('対象の検索条件テンプレートを選択してください。', '未選択');
    return;
  }

  const element = e.target;
  Rails.ajax({
    type: 'PATCH',
    url: `/search_condition_templates/default/${searchCondTemplateId}`,
    beforeSend: () => {
      if (element !== null) element.classList.add('disabled');
      window.triggerEvent(document, 'ajax:send'); // なぜかイベントが発火しないため
      return true;
    },
    complete: () => {
      if (element !== null) element.classList.remove('disabled');
      window.triggerEvent(document, 'ajax:complete'); // なぜかイベントが発火しないため
    },
  });
};

// 検索条件テンプレートの呼び出し
const loadSearchConditionTemplate = () => {
  const searchCondTemplateId = document.getElementById('search_cond_template_id').value;
  if (searchCondTemplateId === '') return;

  let { queryParams } = document.getElementById('search_condition_template').dataset;
  if (queryParams !== '') queryParams = `?query_params=${queryParams}`;

  Turbolinks.visit(`/search_condition_templates/${searchCondTemplateId}${queryParams}`);
};

// 検索条件テンプレートのリセット（検索条件が変更された場合、検索条件テンプレートのselectボックスをリセットする）
const resetSearchConditionTemplate = () => {
  const element = document.getElementById('search_cond_template_id');
  if (element != null) element.selectedIndex = 0;
};

export default function () {
  const searchConditionTemplateDiv = document.getElementById('search_condition_template');
  if (searchConditionTemplateDiv == null) return; // 検索条件テンプレートがない画面は何もしない

  // 保存
  const addElement = document.getElementById('add_search_condition_template');
  if (addElement != null) addElement.addEventListener('click', showSaveDialog);

  // 削除
  const deleteElement = document.getElementById('delete_search_condition_template');
  if (deleteElement != null) deleteElement.addEventListener('click', deleteSearchConditionTemplate);

  // デフォルト
  const defaultElement = document.getElementById('default_search_condition_template');
  if (defaultElement != null) defaultElement.addEventListener('click', swichDefault);

  // テンプレート選択
  const selectElement = document.getElementById('search_cond_template_id');
  if (selectElement != null) selectElement.addEventListener('change', loadSearchConditionTemplate);

  // 検索条件テンプレートのリセット
  const searchForm = document.getElementById('search_form');
  if (searchForm !== null) searchForm.addEventListener('submit', resetSearchConditionTemplate);
}
