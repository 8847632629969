// IEでdispachEventが動作しないための対応

export default function (element, eventType, customEventInit = null) {
  if (window.isIE()) {
    // IE11でdispatchEventが使えないためこの書き方。ただし仕様的にはdeprecated.
    const event = document.createEvent('CustomEvent');
    event.initCustomEvent(eventType, true, false, customEventInit);
    element.dispatchEvent(event);
  } else {
    element.dispatchEvent(new CustomEvent(eventType, customEventInit));
  }
}
