// MDNのサイトより
// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/replaceWith

function ReplaceWith(element, ...args) {
  'use-strict';

  // For safari, and IE > 10
  const parent = this.parentNode;
  let i = args.length;
  const firstIsNode = +(parent && typeof element === 'object');
  if (!parent) return;

  while (i-- > firstIsNode) { // eslint-disable-line no-plusplus
    if (parent && typeof args[i] !== 'object') {
      args[i] = document.createTextNode(args[i]); // eslint-disable-line no-param-reassign
    } if (!parent && args[i].parentNode) {
      args[i].parentNode.removeChild(args[i]);
      continue; // eslint-disable-line no-continue
    }
    parent.insertBefore(this.previousSibling, args[i]);
  }
  if (firstIsNode) parent.replaceChild(element, this);
}
if (!Element.prototype.replaceWith) { Element.prototype.replaceWith = ReplaceWith; }
if (!CharacterData.prototype.replaceWith) { CharacterData.prototype.replaceWith = ReplaceWith; }
if (!DocumentType.prototype.replaceWith) { DocumentType.prototype.replaceWith = ReplaceWith; }
