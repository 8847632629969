// innerHTMLでセットした際に、scriptタグが実行されないので、取得してreplaceする
// 例：
// const element = document.getElementById('report_chart');
// element.innerHTML = "<%= escape_javascript(render 'reports/expenditures/statuses/report_chart') %>";
// window.replaceScript(element);

export default function (element) {
  const elements = element.querySelectorAll('script');
  for (let i = 0; i < elements.length; i += 1) {
    const script = document.createElement('script');
    script.textContent = elements[i].textContent;
    elements[i].replaceWith(script);
  }
}
