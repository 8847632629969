// Highchartのglobal設定

export default function () {
  Highcharts.setOptions({
    global: {
      useUTC: false,
    },
    lang: {
      thousandsSep: ',',
    },
    colors: ['#96C392', '#83B8DB', '#F2DE74', '#D97652', '#7D3A77', '#2679CE', '#B5BD2B'],
    legend: {
      itemHiddenStyle: { color: '#C7C8C8' },
    },
  });
}
