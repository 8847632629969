/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Turbolinks from 'turbolinks';

// polyfill
import 'babel-polyfill';

// ProvidePluginを使う例が多いが、そうすると別エントリポイント（front.js）にも含まれてしまう
// front側ではJQueryは使わないようにするため、application.jsのみで読み込む
import jQuery from 'jquery';

window.Rails = require('rails-ujs');

global.jQuery = jQuery;
global.$ = jQuery;

require('bootstrap/bootstrap.js');

// choices.jsの運営が不安定のためアップデートしていないが、修正したい箇所があるためvender/assets配下に持ってきて自分で修正
window.Choices = require('choices.js-3.0.4/assets/scripts/dist/choices.js');
require('choices.js-3.0.4/assets/styles/css/choices.min.css');

require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.ja.min');
require('bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css');

window.Highcharts = require('highcharts');

window.Big = require('big.js');
window.Cookies = require('js-cookie');

window.Sortable = require('sortablejs/Sortable.min.js');

require('dropzone/dist/min/dropzone.min.css');
require('dropzone/dist/min/basic.min.css');

// ヘルプ検索 algolia
window.algoliasearch = require('algoliasearch');
window.autocomplete = require('autocomplete.js/dist/autocomplete.js');

// デザインテンプレートのファイル
require('ace/ace.js');
require('ace/ace-elements.js');
require('ace/fuelux/fuelux.wizard.min.js');

import SimpleBar from 'simplebar';
global.SimpleBar = SimpleBar;
require('simplebar/dist/simplebar.css');

// tooltip
import { delegate } from 'tippy.js';
window.tippyDelegate = delegate;
import 'tippy.js/dist/tippy.css';

// modal
import A11yDialog from 'a11y-dialog';
window.A11yDialog = A11yDialog;

// scroll lock
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
window.disableBodyScroll = disableBodyScroll;
window.enableBodyScroll = enableBodyScroll;

Rails.start();
Turbolinks.start();

/* eslint-disable import/first */
import '../javascripts/application';
import '../stylesheets/application';
/* eslint-enable import/first */

require.context('../images/', true, /\.(gif|jpg|png|svg)$/i);
