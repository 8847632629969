// クリップボードにコピー

import { isEnter } from '../utils/keyevent_util';

// クリップボードにコピーする
const copy = (e) => {
  if (e.type !== 'click' && !(e.type === 'keyup' && isEnter(e)) && !(e.type === 'keydown' && isEnter(e))) return; // クリックかEnterキー以外は処理しない
  if (e.target.classList === undefined || !e.target.classList.contains('js_text_copy') || e.target.dataset.copyText === undefined) return; // 要素がコピー対象でない場合は処理しない

  // Enter時は、マウスクリック時と同じアイコンの動きになるようにclass付与
  if (e.type === 'keydown' && isEnter(e)) {
    e.target.classList.add('text_copy_icon_active');
  }
  if (e.type === 'keyup' && isEnter(e)) {
    e.target.classList.remove('text_copy_icon_active');
  }

  navigator.clipboard.writeText(e.target.dataset.copyText); // eslint-disable-line no-undef
};

export default function () {
  document.addEventListener('click', copy);
  document.addEventListener('keyup', copy);
  document.addEventListener('keydown', copy);
}
