// tableのチェックボックス全選択
// class='js_check_all'が指定されているチェックボックスで動作する

import closest from '../utils/closest';

// closestModeがtrue場合は、クリックされたチェックボックスのclosestのtable
// falseの場合は、id='search_result_table'のtable
export default function () {
  const searchResultDiv = document.getElementById('search_result');
  if (searchResultDiv === null) return; // 検索結果がない画面は何もしない

  searchResultDiv.addEventListener('click', (e) => {
    if (!e.target.matches('input') || e.target.classList === undefined || !e.target.classList.contains('js_check_all')) return; // 全チェックがない

    let checkboxes = null;
    if (e.target.dataset.closestMode === 'true') {
      checkboxes = closest(e.target, 'table').querySelectorAll('input[type=checkbox]');
    } else {
      checkboxes = document.getElementById('search_result_table').querySelectorAll('input[type=checkbox]');
    }

    Array.prototype.slice.call(checkboxes).forEach((checkbox) => {
      checkbox.checked = e.target.checked; // eslint-disable-line no-param-reassign
    });
  });
}
