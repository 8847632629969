// bootstrap datepicker

// datepickerのデフォルト
const setupDefault = () => {
  $.fn.datepicker.defaults.clearBtn = true;
  $.fn.datepicker.defaults.language = 'ja';
  $.fn.datepicker.defaults.autoclose = true;
  $.fn.datepicker.defaults.zIndexOffset = 10000;
  $.fn.datepicker.defaults.todayHighlight = true;
  $.fn.datepicker.defaults.keyboardNavigation = true;
  $.fn.datepicker.defaults.endDate = '2100-12-31';
};

export default function () {
  setupDefault();
}
