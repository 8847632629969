// 検索画面でのCSVダウンロード
// 「download_token」というhiddenが必要
// 例：<%= csv_download_hiddens %>

import disableDownloadButton from './disable_download_button';

export default function () {
  const csvDownloadMenuItems = document.getElementsByClassName('js_csv_download_button');
  if (csvDownloadMenuItems == null) return; // CSVダウンロードボタンがない画面は何もしない

  const searchForm = document.getElementById('search_form'); // 検索条件のform

  Array.prototype.slice.call(csvDownloadMenuItems).forEach((element) => {
    element.addEventListener('click', (e) => {
      if (element.tagName === 'A' && element.classList.contains('disabled') === true) {
        return; // disabled状態の場合はスキップ（aタグの場合はdisable属性が効かないため）
      }

      disableDownloadButton(element, document.getElementById('download_token').value); // ダウンロードボタンの制御

      // 出力形式
      document.getElementById('export_data_type').value = element.dataset.exportDataType;

      // actionのURLに.csvを付けてremote:falseにしてsubmit。終了したら元に戻す
      const action = searchForm.getAttribute('action').replace('.csv', '');
      searchForm.setAttribute('data-remote', false);
      searchForm.setAttribute('action', `${action}.csv`);

      if (window.isIE() || window.isEdge()) searchForm.setAttribute('target', '_blank');

      searchForm.submit();

      searchForm.setAttribute('data-remote', true);
      searchForm.setAttribute('action', action);

      const buttonElement = $(`#${element.dataset.buttonId}`);
      if (buttonElement.attr('aria-expanded') === 'true') buttonElement.dropdown('toggle');

      e.preventDefault();
      e.stopPropagation();
    });
  });
}
