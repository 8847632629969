if(! ('ace' in window) ) window['ace'] = {}
$(document).on('turbolinks:load', function() {
	//at some places we try to use 'tap' event instead of 'click' if jquery mobile plugin is available
	window['ace'].click_event = $.fn.tap ? "tap" : "click";
});

(function($ , undefined) {
	$.fn.ace_wizard = function(options) {

		this.each(function() {
			var $this = $(this);
			$this.wizard();

			var buttons = $this.siblings('.wizard-actions').eq(0);
			var $wizard = $this.data('wizard');
			$wizard.$prevBtn.remove();
			$wizard.$nextBtn.remove();

			$wizard.$prevBtn = buttons.find('.btn-prev').eq(0).on(ace.click_event,  function(){
				$this.wizard('previous');
			}).attr('disabled', 'disabled');
			$wizard.$nextBtn = buttons.find('.btn-next').eq(0).on(ace.click_event,  function(){
				$this.wizard('next');
			}).removeAttr('disabled');
			$wizard.nextText = $wizard.$nextBtn.text();
		});

		return this;
	}

})(window.jQuery);





