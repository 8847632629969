// data-remote=falseが指定されている場合に、formのremote:trueを切り替える
// PDF出力の際に、remote:trueだとダメなので、そこで指定する
//
// 使用方法
// <%= list_button_menu_with_role :projects, :bulk_print,
//       fa_svg_solid("file", width: 9, height: 12, class: "svg_icon_left_right3"), "印刷",
//       @project_print_action, "print", "primary",
//       formaction: "/projects/bulk_print", formmethod: :post,
//       formtarget: :_blank, data: { form_remote: false } %>

// formのtarget属性を切り替え
const setFormTarget = (form, formRemote) => {
  if (formRemote !== undefined && formRemote !== null) {
    form.setAttribute('data-remote', formRemote);
    if (formRemote === 'false') {
      // remote:falseの場合、自動的にmetaタグのcsrf-tokenが使われないため、明示的にセットする
      const hidden = document.createElement('input');
      hidden.type = 'hidden';
      hidden.name = 'authenticity_token';
      hidden.value = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      form.appendChild(hidden);
    }
  } else {
    form.setAttribute('data-remote', 'true'); // 指定されていない場合は、通常の状態に戻す
  }
};

export default function (form, formRemote) {
  setFormTarget(form, formRemote); // formのtarget属性を切り替え
}
