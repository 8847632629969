// 戻りURL制御

// // 戻り先URLを保存
const saveReturnUrl = () => {
  if (document.body.dataset.returnUrl !== undefined) {
    sessionStorage.setItem('return_url', document.body.dataset.returnUrl);
  }
};

// 戻り先の情報をセット
const setReturnUrl = () => {
  if (sessionStorage.getItem('return_url')) {
    // 戻り先URLとアクセスURLを取得（pathnameを取得するため、createElementする）
    const accessUrl = document.createElement('a');
    accessUrl.href = window.location.href;
    const returnUrl = document.createElement('a');
    returnUrl.href = sessionStorage.getItem('return_url');

    // 戻り先URIとアクセスURIが一致しなければ戻り先URLを設定する
    if (accessUrl.pathname !== returnUrl.pathname) {
      // a
      $('a.link_to_return').attr('href', sessionStorage.getItem('return_url'));
      // from
      $('<input>').attr({
        type: 'hidden',
        name: 'return_url',
        value: sessionStorage.getItem('return_url'),
      }).appendTo('form');
    }
  }
};

export default function () {
  if (sessionStorage === undefined) return;

  saveReturnUrl();
  setReturnUrl();
}
