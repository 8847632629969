// tooltip表示

// ■要件
// 以下が必要
// data-tippy-placement="ツールチップ位置"（default: 'top'）
// ref. https://atomiks.github.io/tippyjs/v6/all-props/#placement
// data-tippy-content="ツールチップに表示する内容"
// data-tippy-aria-attribute="labelledby"（aria-labelledby付与の場合のみ、aria-describedby付与の場合は不要）
// data-tippy-theme="large"（normalの場合は不要、表示の違いはtooltip.scss参照）
// 例）
// <button data-tippy-placement="bottom" data-tippy-content="xxx" data-tippy-aria-attribute="labelledby" data-tippy-theme="large"></button>
//
// - 改行表示
// tippyのallowHTML:trueはXSSの脆弱性があるので、allowHTMLは使わず改行表示させるために、ツールチップのCSSに「white-space: pre-wrap;」を設定している。
// 改行表示させたい場合は、「\n」を入れる。→ data-tippy-content="テスト\nテスト"

export default function () {
  // iPad・iPhoneだとtooltipがあるリンクだと2回タップが必要になってしまうため
  if (window.isiOS()) return;

  // escapeキーでtooltipを非表示にするためのplugin
  const hideOnEsc = {
    name: 'hideOnEsc',
    defaultValue: true,
    fn({ hide }) {
      function onKeyDown(event) {
        if (event.code === 'Escape') {
          hide();
        }
      }

      return {
        onShow() {
          document.addEventListener('keydown', onKeyDown);
        },
        onHide() {
          document.removeEventListener('keydown', onKeyDown);
        },
      };
    },
  };

  window.tippyDelegate('body', {
    target: '[data-tippy-content]',
    appendTo: document.body,
    plugins: [hideOnEsc],
    theme: 'normal',
    onCreate({ reference, setProps }) {
      setProps({
        interactive: true,
        aria: {
          content: reference.dataset.tippyAriaAttribute === 'labelledby' ? 'labelledby' : 'describedby',
          expanded: false,
        },
      });
    },
  });
}
