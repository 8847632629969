// Ajaxフォーム系でJSでエラーメッセージを挿入する画面で仕様
// エラー後に正常に終了した場合などにエラーメッセージを削除するために使用する

export default function () {
  const errorMessageArea = document.querySelector('.js_error_message');
  if (errorMessageArea != null) {
    if (window.isIE()) {
      errorMessageArea.parentNode.removeChild(errorMessageArea);
    } else {
      errorMessageArea.remove();
    }
  }
}
