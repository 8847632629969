import closest from '../utils/closest';

// 検索結果の行をクリックした時の処理
export default function () {
  const searchResult = document.getElementById('search_result');
  if (searchResult == null) return; // 検索結果がない画面は何もしない

  searchResult.addEventListener('click', (e) => {
    if (!e.target.matches('td') || e.target.classList.contains('exclude_link_js')) return;

    const tr = closest(e.target, 'tr');

    if (((e.ctrlKey && !e.metaKey)
      || (!e.ctrlKey && e.metaKey)) && tr.dataset.ctrl_url !== undefined) {
      Turbolinks.visit(tr.dataset.ctrl_url);
    } else if (tr.dataset.url !== undefined) {
      Turbolinks.visit(tr.dataset.url);
    }
  });
}
