// loading表示
export default function () {
  if (document.getElementById('loading') == null) return;

  // turbolinks遷移時
  document.addEventListener('turbolinks:request-start', () => {
    document.getElementById('loading').style.display = 'inline';
  });
  document.addEventListener('turbolinks:request-end', () => {
    document.getElementById('loading').style.display = 'none';
  });

  // Ajax処理時
  document.addEventListener('ajax:send', (e) => {
    if (showLoadingMode) document.getElementById('loading').style.display = 'inline';

    // Aタグのボタンの場合は、disableにする
    const element = e.target;
    if (element !== null && element.tagName === 'A' && element.classList.contains('btn')) { element.classList.add('disabled'); }
  });
  document.addEventListener('ajax:complete', (e) => {
    document.getElementById('loading').style.display = 'none';

    // Aタグのボタンの場合は、disableを解除する
    const element = e.target;
    if (element !== null && element.tagName === 'A' && element.classList.contains('btn')) element.classList.remove('disabled');

    if (e.detail !== undefined) {
      if (e.detail[0].status === 401) {
        // Ajaxリクエストでサーバーサイドがセッションタイムアウトしていた場合はリロードする（リロード時にログイン画面にリダイレクトされる）
        window.location.reload();
      } else if (e.detail[0].status === 404) {
        window.dialogAlert.show('データが見つかりません。<br><br>再度同じ操作をしてもエラーになる場合はお問い合わせください。');
      } else if (e.detail[0].status === 500) {
        window.dialogAlert.show('エラーが発生しました。<br><br>ご迷惑をおかけして申し訳ありません。<br>再度同じ操作をしてもエラーになる場合はお問い合わせください。');
      } else if (e.detail[0].status === 422) {
        window.dialogAlert.show('処理を完了できませんでした。<br><br>画面をリロード後、再度同じ操作をしてもエラーになる場合はお問い合わせください。');
      }
    }
  });
}
