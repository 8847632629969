// ダウンロードボタンをdisabledにする処理
// パラメータに「download_token」というパラメータが必要
// 例：<%= csv_download_hiddens %>

const downloadCheckerCookieName = 'download_token'; // ダウンロード制御用Cookie名
let downloadTimer = null;

// ダウンロード制御をストップ
const stopDownloadControl = () => {
  window.clearInterval(downloadTimer); // Timer停止
  Cookies.remove(downloadCheckerCookieName); // 制御用Cookie削除
};

export default function (buttonElement, donwloadToken) {
  let attempts = 30; // Timerがずっと回らないように、上限回数

  buttonElement.classList.add('disabled');
  if (buttonElement.tagName === 'A') {
    buttonElement.setAttribute('onclick', 'return false;'); // Aタグクリックが動かないようにする
  }

  downloadTimer = window.setInterval(() => {
    const token = Cookies.get(downloadCheckerCookieName); // サーバー側からセットされるCookieを取得

    if ((token === donwloadToken) || (attempts === 0)) {
      // サーバーからセットされたCookieと元々送信したものをチェックし、一致してたらダウンロード済み
      stopDownloadControl();

      buttonElement.classList.remove('disabled');
      if (buttonElement.tagName === 'A') {
        buttonElement.removeAttribute('onclick'); // Aタグクリックを動かないようにしているのを解除
      } else if (buttonElement.tagName === 'INPUT') {
        buttonElement.removeAttribute('disabled'); // disable_withでdisabledされている想定で、解除だけここで行う
      }
    }

    attempts -= 1;
  }, 1000);
}
