// 検索結果の置き換え処理

import fadeOutIn from '../utils/fadeout_in';

export default function (html, controllerPath = null, actionName = null, callback = null, targetElementId = 'search_result') {
  const searchResult = document.getElementById(targetElementId);
  fadeOutIn(
    searchResult,
    () => {
      window.indexPageSearched = true; // 検索条件が指定されて、検索結果だけ再描写したことを示す
      searchResult.innerHTML = html;

      // 表示しようとしているcontroller・actionの初期処理を呼び出し
      if (controllerPath != null && actionName != null) {
        window.initPage(controllerPath, actionName);
      }

      if (callback != null) callback();
    },
  );
}
