// 文字列型になっている数値関連のユーティリティ

// カンマを除く
export function removeComma(str) {
  return (str === undefined || str === null) ? null : str.toString().replace(/,/g, '').trim();
}

// 全角数字を半角に変換
export function convertToHankakuNum(number) {
  const hankaku = (`${number}`).replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
  return hankaku.replace(/[。．。]/, '.').replace(/[ー－−―‐]/, '-');
}

// 通貨記号を除く
export function removeCurrencySymbol(str) {
  return (str === undefined || str === null) ? null : str.toString().replace(/¥|\$|€|£|₩|฿|円|元/g, '');
}
