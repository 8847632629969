// 数字の自動フォーマットのイベントをセット
// 対象にするには、class="number_input"を付ける

import NumberFormat from '../utils/number_format';
import { convertToHankakuNum, removeCurrencySymbol } from '../utils/string_number_util';

import { countChar } from '../utils/string_util';
import { isEnter } from '../utils/keyevent_util';

// カンマの追加・削除などに応じて、カーソル位置を調整する
const adjustCursorPosition = (oldText, newText, selectionStart, selectionEnd) => {
  let startPosition = selectionStart;
  let endPosition = selectionEnd;
  const oldTextBeforeSelectionStart = oldText.slice(0, startPosition); // 変更前のテキストで、カーソルより前のもの
  const newTextBeforeSelectionStart = newText.slice(0, startPosition); // 変更後のテキストで、カーソルより前のもの
  const oldTextAfterSelectionEnd = oldText.slice(endPosition); // 変更前のテキストで、カーソルより後のもの
  const newTextAfterSelectionEnd = newText.slice(endPosition); // 変更後のテキストで、カーソルより前のもの

  // 変更前後でカンマの数が変わっているかどうか
  const diffNumOfCommaBeforeCursor = countChar(oldTextBeforeSelectionStart, ',') - countChar(newTextBeforeSelectionStart, ',');
  const diffNumOfCommaAfterCursor = countChar(oldTextAfterSelectionEnd, ',') - countChar(newTextAfterSelectionEnd, ',');

  // カンマの数が一致しない場合は、カンマが入って位置がずれているので、selectionの位置調整
  if (diffNumOfCommaBeforeCursor < 0) {
    startPosition += 1;
    endPosition += 1;
  } else if (diffNumOfCommaBeforeCursor > 0
             || diffNumOfCommaAfterCursor > 0) { // diffNumOfCommaAfterCursorは文字削除時
    startPosition -= 1;
    endPosition -= 1;
  }

  return [startPosition, endPosition];
};

// inputの値をformatする
const formatInputValue = (e) => {
  if (e.isComposing === true) return; // 入力中のものは何もしない（isComposingは一部ブラウザしか対応していないが、Windows版Chromeで、「テンキー＋日本語入力モード」の場合に二重で入力されてしまう問題の対応のためなので、対応していない場合はreturnにならなくても問題なし

  const maxFractionDigits = e.target.dataset.maxFractionDigits !== undefined ? e.target.dataset.maxFractionDigits : 0;
  const numberFormat = new NumberFormat(maxFractionDigits);

  let inputValue = e.target.value; // inputのvalue
  inputValue = removeCurrencySymbol(inputValue);

  const firstChar = inputValue.slice(0, 1); // 最初の文字
  const secondChar = inputValue.slice(1, 2); // 2文字目
  const lastChar = inputValue.slice(-1); // 末尾の文字
  let { selectionStart, selectionEnd } = e.target; // カーソル位置

  // 小数点は maxFractionDigits までなので、maxFractionDigits + 1桁目が入力されてたら落とす
  const indexOfDigit = inputValue.lastIndexOf('.');
  if (indexOfDigit !== -1 && indexOfDigit === inputValue.length - maxFractionDigits - 2) {
    inputValue = inputValue.slice(0, inputValue.length - 1);
  }

  // focusoutイベント・Enter時は、全角→半角変換する
  if (e.type === 'focusout' || (e.type === 'keyup' && isEnter(e))) {
    inputValue = convertToHankakuNum(inputValue);
  }

  // 全角半角変換対象が含まれている場合は何もしない（focusoutイベント時に処理するため）
  if (inputValue.match(/[０-９。．。ー−―‐]/)) return;

  if (window.isiOS() || window.isAndroid()) {
    // iOS・Androidの場合は、入力中に自動formatはしない（日本語モードで入力しているとおかしくなるため）
    if (e.type === 'focusout') e.target.value = numberFormat.format(inputValue);
  } else {
    // 編集中でformatを実行してはいけない場合はformatしない。（focusout時のみformatする）
    // inputValue.match(/\./)は、100.0などで自動フォーマットされないように、リアルタイムではformatしない（100.0は100だが、100.01などのケースがあるため）
    e.target.value = (e.type !== 'focusout'
          && (firstChar === '0' || firstChar === ',' || firstChar === '.'
          || (firstChar === '-' && secondChar === '0') || lastChar === '.'
          || lastChar === '-' || (inputValue.match(/\./)))) ? inputValue : numberFormat.format(inputValue);
  }

  if (e.type === 'focusout') {
    // IE11・Edge・Safariで、e.target.valueにセットした影響でchangeイベントが発火しないためdispatchする
    if (window.isIE() || window.isEdge() || window.isSafari()) window.triggerEvent(e.target, 'change');
  } else {
    // focusoutイベント時はカーソル位置の調整は不要（focusoutしたのにカーソルが戻ってしまうため）

    // カンマの追加・削除・位置変更に伴うカーソル位置の調整
    [selectionStart, selectionEnd] = adjustCursorPosition(inputValue, e.target.value, selectionStart, selectionEnd);

    e.target.setSelectionRange(selectionStart, selectionEnd);
  }
};

// 自動フォーマットのイベントを登録
const setupAutoFormatEvent = (element) => {
  element.addEventListener('keyup', formatInputValue);
  element.addEventListener('input', formatInputValue);
  element.addEventListener('focusout', formatInputValue);
};

// 自動フォーマットのイベントを削除
const removeAutoFormatEvent = (element) => {
  element.removeEventListener('keyup', formatInputValue);
  element.removeEventListener('input', formatInputValue);
  element.removeEventListener('focusout', formatInputValue);
};

export default function () {
  // テキストボックスにfocusが当たった時に、自動フォーマットする処理を発火するイベントを設定する
  document.addEventListener('focusin', (e) => {
    if (e.target.classList !== undefined && e.target.classList.contains('number_input')) setupAutoFormatEvent(e.target);
  });

  document.addEventListener('focusout', (e) => {
    // テキストボックスからfocusが外れた時に、自動フォーマットする処理を発火するイベントを削除する
    if (e.target.classList !== undefined && e.target.classList.contains('number_input')) removeAutoFormatEvent(e.target);
  });
}
