export default function (el, selector) {
  const matches = el.matches || el.webkitMatchesSelector
                    || el.mozMatchesSelector || el.msMatchesSelector;

  let element = el;
  while (element) {
    if (matches.call(element, selector)) {
      return element;
    }
    element = element.parentElement;
  }
  return null;
}
