// 一覧の表示設定

// 一覧表示項目設定のダイアログ表示
const showListFieldsSetting = (e) => {
  if (e.target.id !== 'list_fields_setting') return;

  const element = e.target;
  Rails.ajax({
    type: 'GET',
    url: `/common/list_visible_fields/edit?list_kbn=${e.target.dataset.listKbn}`,
    beforeSend: () => {
      if (element !== null) element.classList.add('disabled');
      window.triggerEvent(document, 'ajax:send'); // なぜかイベントが発火しないため
      return true;
    },
    complete: () => {
      if (element !== null) element.classList.remove('disabled');
      window.triggerEvent(document, 'ajax:complete'); // なぜかイベントが発火しないため
    },
  });
};

export default function () {
  const searchResult = document.getElementById('search_result');
  if (searchResult == null) return; // 検索結果がない画面は何もしない

  searchResult.addEventListener('click', showListFieldsSetting);
}
