// algoliaによるヘルプ検索

export default function () {
  const searchElement = document.getElementById('algolia_search_help_input');
  if (searchElement === null) return;

  const client = algoliasearch(process.env.algoliaId, serverVariables.algoliaSearchOnlyApiKey);
  // staging環境が複数あるので、stg2, stg3などでもstagingのindexを参照するように以下の条件にする
  const index = client.initIndex(serverVariables.algoliaIndex);

  autocomplete('#algolia_search_help_input', { hint: false, openOnFocus: true }, [
    {
      source: autocomplete.sources.hits(index, { hitsPerPage: 5 }),
      // displayKey: 'title', ※ hintを有効にする際はコメントを外す
      templates: {
        suggestion(suggestion) {
          return `<small class="help_type_label">${suggestion.help_type}</small>&nbsp;${suggestion._highlightResult.title.value}`; // eslint-disable-line no-underscore-dangle
        },
        // eslint-disable-next-line no-unused-vars
        footer({ query, isEmpty }) {
          if (isEmpty) return null;
          return '<div class="aa-suggestion" role="option">その他の検索結果を見る</div>';
        },
        empty: '<div class="algolia-autocomplete"><div class="aa-no-search-result">検索結果なし</div></div>',
      },
    },
  ]).on('autocomplete:selected', (event, suggestion) => {
    // 別タブで開く
    if (suggestion === undefined) {
      //
      window.open(`/helps/search?q=${encodeURIComponent(event.target.value)}`, '_blank');
    } else {
      window.open(`/blogs/${suggestion.blog_key}`, '_blank');
    }
  });
}
