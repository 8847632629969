// フェードアウト・フェードイン
// 対象の要素が、class="fadein"が指定されている必要あり
// callbackが指定されている場合はそれを実行する
export default function (element, callback) {
  element.classList.remove('in');

  window.setTimeout(() => {
    callback();
    element.classList.add('in');
  }, 400); // 秒数は.fadeinの定義と合わせる
}
