// ブラウザ判定
// browser Gemを使って、bodyのdata属性にセットしている値を使う前提（JSでUAを見ての判定はしない）

// IEかどうか
export function isIE() { // eslint-disable-line import/prefer-default-export
  return serverVariables.isIE === 'true';
}

// Edgeかどうか
export function isEdge() { // eslint-disable-line import/prefer-default-export
  return serverVariables.isEdge === 'true';
}

// Safariかどうか
export function isSafari() { // eslint-disable-line import/prefer-default-export
  return serverVariables.isSafari === 'true';
}

// Firefoxかどうか
export function isFirefox() { // eslint-disable-line import/prefer-default-export
  return serverVariables.isFirefox === 'true';
}

// iOSかどうか
export function isiOS() { // eslint-disable-line import/prefer-default-export
  return serverVariables.isiOS === 'true';
}

// Androidかどうか
export function isAndroid() { // eslint-disable-line import/prefer-default-export
  return serverVariables.isAndroid === 'true';
}
