// ・クエリー文字列から値が空のパラメータを取り除く
// ・指定の除外パラメータを削除
// ・dummyForEmptyが指定されている場合、パラメータが全て空の場合はダミーをセット

export default function (query, excludes = [], dummyForEmpty = '') {
  const arr = [];

  query.split('&').forEach((params) => {
    const param = params.split('=');
    if (excludes.indexOf(param[0]) === -1) { // 除外するパラメータを除く
      if (param[1]) { arr.push(params); } // 値が入っている場合のみ
    }
  });
  if (arr.length === 0 && dummyForEmpty !== '') arr.push(dummyForEmpty); // パラメータが全て空の場合、指定のダミーをセット
  return arr.join('&');
}
