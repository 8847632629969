// changeイベント時に自動検索処理

import pushAjaxSearchHistory from './push_ajax_search_history';

// Ajax処理を履歴に残す場合に、ベースとなるURLを取得
const getBaseUrl = (url) => {
  const baseUrl = document.baseURI === undefined ? document.URL : document.baseURI;
  return (baseUrl.indexOf('rurl=1') === -1) ? url : `${url}?rurl=1`; // rurlが付いている場合はそれだけ維持する
};

// 検索実行
const execSearch = (e) => {
  if (e.target.classList.contains('js_auto_search')) {
    const searchForm = document.getElementById('search_form');
    Rails.fire(searchForm, 'submit');

    pushAjaxSearchHistory(getBaseUrl(searchForm.getAttribute('action')));
  }
};

// 自動検索実行処理
export default function () {
  const searchCondDiv = document.getElementById('search_cond');
  if (searchCondDiv == null) return; // 検索条件がない画面は何もしない

  // 通常のinput・select変更時の検索実行
  searchCondDiv.addEventListener('change', execSearch);

  // datepickerで日付が変更された時の検索実行
  // JQuery依存（bootstrap datepicker）
  $('#search_cond').on('changeDate', execSearch);
}
