// 画面起動時に、number_inputが指定されているものは全てフォーマットする

import NumberFormat from '../utils/number_format';

export default function () {
  const elements = Array.prototype.slice.call(document.getElementsByClassName('number_input'));

  elements.forEach((element) => {
    const maxFractionDigits = element.dataset.maxFractionDigits !== undefined ? element.dataset.maxFractionDigits : 0;
    const numberFormat = new NumberFormat(maxFractionDigits);
    element.value = numberFormat.format(element.value); // eslint-disable-line no-param-reassign
  });
}
