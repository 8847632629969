// チェックを付けて一覧下のボタンで操作する一覧関連の共通処理

import setFormTarget from './form_target';

// ドロップダウンボタンの場合はドロップダウンを閉じる
// TODO: bootstrapのdropdownを使っているため、JQueryが残っている
const closeDropdownMenu = (e) => {
  const button = $(`#${e.target.dataset.buttonId}`);
  if (button.attr('aria-expanded') === 'true') button.dropdown('toggle');
};

// チェックされているかどうか
const validateChecked = (e, listForm) => {
  const checkboxes = listForm.querySelectorAll('table input[type=checkbox]:checked');

  if (checkboxes.length === 0) {
    e.preventDefault();
    e.stopPropagation();
    window.dialogAlert.show('対象が選択されていません。<br><br>一覧のチェックボックスで対象を選択してください。', '未選択');
    closeDropdownMenu(e);
    return false;
  }
  return true;
};

export default function () {
  const listForm = document.getElementById('list_form');
  if (listForm == null) return; // 存在しない画面は何もしない

  // ボタンドロップダウンの選択しクリック時の処理
  listForm.addEventListener('click', (e) => {
    const listItem = e.target;

    if (listItem.getAttribute('type') === 'submit') {
      setFormTarget(listForm, listItem.dataset.formRemote); // formのtarget属性を切り替え
      if (listItem.dataset.skipListValidateion !== 'true') {
        if (!validateChecked(e, listForm)) return; // チェックが付いていない場合はalert
      }
      closeDropdownMenu(e); // ドロップダウンボタンの場合はドロップダウンを閉じる
    }
  });
}
