import closest from '../utils/closest';

const finishTypingInterval = 300;
let searchTimer = null;

// Ajaxでリスト取得処理
const fetchList = (term, choice, element) => {
  choice.ajax(() => {
    window.triggerEvent(document, 'ajax:send');

    // 複数選択項目の場合、すでに選択済のものは除外するために、選択済のデータのIDを送る
    const excludeIds = [];
    if (choice.isSelectMultipleElement === true) {
      const selectedItems = choice.getValue();
      if (selectedItems !== null) {
        selectedItems.forEach((item) => {
          excludeIds.push(item.value);
        });
      }
    }

    const specifiedExcludeId = element.getAttribute('data-exclude-id');
    if (specifiedExcludeId !== null) excludeIds.push(specifiedExcludeId);

    const targetUrl = element.getAttribute('data-target-url');
    const parameterDelimiter = targetUrl.indexOf('?') === -1 ? '?' : '&';
    const { alwaysSearchList } = element.dataset;

    fetch(`${targetUrl}${parameterDelimiter}search=${encodeURIComponent(term)}&exclude_ids=${excludeIds.join(',')}`, {
      credentials: 'same-origin',
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          window.triggerEvent(document, 'ajax:complete');

          if (window.fixedChoicesList[element.id]) return; // リストが固定されている場合は何もしない

          choice.setChoices(data, 'value', 'label', true);

          // placeholderがクリアされてしまうが、choicesの仕様的に対応できなさそうなので、力技
          if (choice.placeholder !== null) {
            const inputElement = closest(element, '.choices__inner').querySelector('input.choices__input');
            if (inputElement != null) inputElement.setAttribute('placeholder', choice.placeholder);
          }

          // これ以降の処理は、検索条件のchoices固有のものなので、それ以外のものはここで終了
          if (element.classList.contains('js_choices_ajax_search') !== true) return;

          // キーワード未指定で300件未満の場合は以降は動的に取得する必要がないので、イベント削除
          const parser = new URL(response.url);
          const searchedTerms = parser.searchParams.get('search');
          const excludedIds = parser.searchParams.get('exclude_ids');

          // 「300件未満」という条件だと、名称が重複している場合に、それより少なくなってしまうため、検索が実行されなくなる問題があるため、暫定対応として250にする
          if (searchedTerms === '' && excludedIds === '' && data.length < 250) {
            if (alwaysSearchList !== 'true') {
              window.fixedChoicesList[element.id] = true;

              // これらからfetchListを呼び出しているため、必ずどちらかがこのルールを違反してしまうためdisable
              /* eslint-disable no-use-before-define */
              element.removeEventListener('showDropdown', searchIfChoiceExist);
              element.removeEventListener('search', searchWithTimer);
              /* eslint-enable no-use-before-define */
            }
          }
        });
      } else {
        response.json().then((data) => {
          window.triggerEvent(document, 'ajax:complete');
          window.dialogAlert.show(`リストの取得に失敗しました。<br><br>${data.message === null ? '' : data.message}`);
        });
      }
    });
  });
};

// choiceオブジェクトが存在する場合に検索実行
const searchIfChoiceExist = (e) => {
  const choice = window.choicesElements.get(e.target.id);
  fetchList('', choice, e.target);
};

// 検索処理（Timerで、指定秒数次のイベントがなかったら検索を実行する）
const searchWithTimer = (e) => {
  const choice = window.choicesElements.get(e.target.id);
  clearTimeout(searchTimer);
  searchTimer = setTimeout(
    () => { fetchList(e.detail.value, choice, e.target); },
    finishTypingInterval,
  );
};

// Dropdownを非表示にする
const hideDropdown = (e) => {
  window.choicesElements.get(e.target.id).hideDropdown();
};

// 未選択状態にする
const unselectChoice = (e) => {
  if (e.target.value === '') window.choicesElements.get(e.target.id).setValueByChoice('');
};

// choices.jsのセットアップ
const setupChoices = (element, ajaxSearchMode) => {
  const choice = new Choices(element, {
    silent: true,
    itemSelectText: '',
    shouldSort: false,
    shouldSortItems: false,
    noResultsText: '該当なし',
    noChoicesText: '',
    loadingText: '',
    removeItemButton: element.getAttribute('data-remove-item-button') !== 'false',
    searchFields: ['label'],
    searchResultLimit: 300,
    renderChoiceLimit: 300,
    placeholderValue: element.getAttribute('data-placeholder'), // select-multiple用
    fuseOptions: {
      threshold: 0.3,
    },
    classNames: {
      // 本来はplaceholder自体を未指定にしたいが、choices.jsの仕様上、placeholderではない空のoptionがあると、
      // 高さが10px（padding分の高さ）の空の選択肢が表示されてしまうため、display: noneにする
      // placeholderはコントラスト比の問題があるため原則使用しない前提で、display: noneをデフォルトにする
      // もし表示したい場合は、data-show-placeholderを指定することで表示できる
      placeholder: element.getAttribute('data-show-placeholder') ? 'choices__placeholder' : 'choices__placeholder_hidden',
    },
  });

  // タグ選択時に自動的に閉じないので、明示的にメソッドを呼ぶ
  if (choice.isSelectMultipleElement) element.addEventListener('choice', hideDropdown);

  // Ajaxでの検索処理
  if (ajaxSearchMode) {
    // 開いた時に検索を実行する（デフォルトで100件表示するため）
    element.addEventListener('showDropdown', searchIfChoiceExist);

    // 検索処理
    element.addEventListener('search', searchWithTimer);

    // リストを動的に取得した後、選択せずに他の部分をクリックした際（=リストを閉じた際）に
    // placeholderが表示されない状態になるので明示的に未選択状態にする
    element.addEventListener('hideDropdown', unselectChoice);
  }

  // data-label-idがある場合は、それを使ってaria-labelledbyを追加する
  // labelとフォーム要素はlabel forで紐付いているが、choicesがdivでラップしているため、
  // choicesのセレクトボックスを読み上げた時に、項目名がわからないため、aria-labelledbyで紐付ける
  const { labelId } = element.dataset;
  if (labelId !== undefined && labelId !== '') {
    choice.containerOuter.setAttribute('aria-labelledby', labelId);
  }

  return choice;
};

export default function () {
  if (document.getElementsByClassName('js_choices').length === 0) return;

  if (window.choicesElements === undefined) {
    window.choicesElements = new Map(); // choicesは、グローバル変数に保存しておく。Ajax処理のレスポンス（xxx.js.erb）で使う
  }

  const elements = Array.prototype.slice.call(document.getElementsByClassName('js_choices'));

  elements.forEach((element) => {
    const ajaxSearchMode = element.classList.contains('js_choices_ajax_search'); // Ajax検索用かどうか

    if (window.choicesElements.get(element.id) === undefined) {
      const choice = setupChoices(element, ajaxSearchMode);
      if (element.id !== '') window.choicesElements.set(element.id, choice);
    }
  });
}
