// IEでdispachEventが動作しないための対応

export default function (element, eventType) {
  if (window.isIE() || window.isEdge() || window.isSafari()) {
    // IE11・Edge・SafariでdispatchEventが使えないためこの書き方。ただし仕様的にはdeprecated.
    const event = document.createEvent('Event');
    event.initEvent(eventType, true, false);
    element.dispatchEvent(event);
  } else {
    element.dispatchEvent(new Event(eventType));
  }
}
